import React from "react";
 
const Index = () => {
  return (
    <div
      className="center d-flex justify-content-center align-items-center"
      style={{ height: "65vh" }}
    >
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
  );
};
 
export default Index;