import './App.css';
import Layout from './container/layout';
import { Routes, Route, BrowserRouter as Router, } from 'react-router-dom'
import RenewSubscription from './pages/renew-subscription';
import NotFound from './components/not-found/index'

function App() {
  return (
    <>
      <Router >
        <Routes>
          <Route element={<Layout />} >
            <Route path="/payment/:paymentId" element={<RenewSubscription />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
