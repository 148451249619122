import React from "react";
import CheckMark from "../../assets/icons/check-mark.svg";
import SuccessPayment from "../../assets/icons/SuccessPayment.png";
import { MdOutlineCancel } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";

function SuccessPage({ subDomain, isPaymentExpired, isAlreadyPaid }) {
    return (
        <div className="py-10 lg:py-20">
            <div className="sm:w-[90%] md:w-[80%] lg:w-[70%] xl:w-[60%] m-auto">
                {isPaymentExpired ? (
                    // Case for expired payment link
                    <>
                        <div className="flex justify-center">
                            <IoMdCloseCircle className="text-[#F56565] progressIcon" size={70} />
                        </div>
                        <div className="text-center text-2xl md:text-3xl xl:text-4xl text-[#F56565] font-bold pt-4">
                            Payment Link Expired
                        </div>
                        <p className="text-[#778399] sm:text-sm xl:text-base font-normal text-center sm:mt-0 mt-4">
                            Unfortunately, the payment link has expired. Please try again or
                            contact support for assistance.
                        </p>
                        <div className="w-[60%] sm:w-[35%] mx-auto mt-4">
                            <a href="mailto:support@leadsynq.com ">
                                <button className="text-center bg-[#F56565] text-white w-full rounded-lg h-[48px] text-base font-normal">
                                    Contact Support
                                </button>
                            </a>
                        </div>
                    </>
                ) : isAlreadyPaid ? (
                    // Case for payment already processed
                    <>
                        <div className="flex justify-center">
                            <FaCheckCircle color="green" size={75} />
                        </div>
                        <div className="text-center text-2xl md:text-3xl xl:text-4xl text-[#0F337C] font-bold pt-5 flex justify-center">
                            Payment Already Processed
                        </div>
                        <p className="text-[#778399] sm:text-sm xl:text-base font-normal text-center sm:mt-0 mt-4">
                            Your payment has already been processed. If you believe this is an
                            error, please contact support.
                        </p>
                        <div className="w-[60%] sm:w-[35%] mx-auto mt-4">
                            <a
                                href={`https://${subDomain}.leadsynq.com`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <button className="text-center bg-[#051F54] text-white w-full rounded-lg h-[48px] text-base font-normal">
                                    Sign in to Dashboard
                                </button>
                            </a>
                        </div>
                    </>
                ) : (
                    // Case for successful payment
                    <>
                        <div className="flex justify-center">
                            {/* <img src={CheckMark} alt="check-mark" /> */}
                            <FaCheckCircle color="green" size={75} />
                        </div>
                        <div className="text-center text-2xl md:text-3xl xl:text-4xl text-[#0F337C] font-bold pt-5 flex justify-center">
                            Subscription Renewal Successful!{" "}
                            <img
                                src={SuccessPayment}
                                alt={"success"}
                                className="mx-1 w-12 md:w-14 relative top-[-20px] sm:flex hidden"
                            />
                        </div>
                        <p className="text-[#778399] sm:text-sm xl:text-base font-normal text-center sm:mt-0 mt-5">
                            Thank you for renewing your subscription. Your payment has been
                            successfully processed, and your account is now updated.
                        </p>
                        <div className="w-[60%] sm:w-[35%] mx-auto mt-3">
                            <a
                                href={`https://${subDomain}.leadsynq.com`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <button className="text-center bg-[#051F54] text-white w-full rounded-lg h-[48px] text-base font-normal">
                                    Sign in to Dashboard
                                </button>
                            </a>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default SuccessPage;
