/*
    |------------------------------Configuration File------------------------------|
*/

const config = {
  localhost: {
    url: "http://localhost:3031/",
    apiUrl: "http://localhost:8081/dev/",
    paymentLink: "http://localhost:3001/",
    stage: "dev",
  },
  develop: {
    url: "https://www.leadsynq.com",
    paymentLink: "https://pay.leadsynq.com",
    apiUrl: "https://s72r6hav0g.execute-api.ap-south-1.amazonaws.com/dev/",
    stage: "dev",
  },
  prod: {
    url: "https://staffq.in/",
    paymentLink: "https://www.leadsynq.com",
    apiUrl: "https://45qcttm7nk.execute-api.ap-south-1.amazonaws.com/prod/",
    stage: "prod",
  },
};

const environment = "develop";

const hostConfigure = {
  API_URL: config[environment].apiUrl,
  PAYMENT_SITE_URL: config[environment].paymentLink,
  ACCESS_KEY_ID: `${config[environment].keyId}`,
  SECRET_ACCESS_KEY: `${config[environment].accessKey}`,
  AWS_REGION: "ap-south-1",
  STAGE: config[environment].stage,
  SECRET_KEY: "leadSynQApi",

  EXPIRES_IN: {
    ACCESS: 2,
    REFRESH: 5,
  },
  ENV: config[environment],
};

const emails = {
  // admin_leadSynQ: process.env.LEADSYNQ_ADMIN_MAIL,
  // team_leadSynQ: process.env.LEADSYNQ_MAIL,
  admin_leadSynQ: "naveenkumar.n.applogiq@gmail.com",
  team_leadSynQ: "naveenkumar.n.applogiq@gmail.com",
};

module.exports = {
  hostConfigure,
  emails,
};
