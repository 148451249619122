import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InfoIcon from "../../assets/icons/info-icon.svg";
import SuccessPage from "./success-page";
import { renewalDetails } from "../../api/list";
import { createOrder } from "../../api/create";
import { hostConfigure } from "../../config";
import Preloader from "../../components/loading/index"
import NotFound from '../../assets/images/leadq-404.png'
import { useParams } from "react-router-dom";
import { getByLinkData } from "../../api/view";


function RenewSubscription() {

  const { paymentId } = useParams();

  const [successPage, setSuccessPage] = useState(false);
  const [expirePage, setExpirePage] = useState(false);
  const [alreadyPaid, setAlreadyPaid] = useState(false);
  const [customerData, setCustomerData] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [plan, setPlan] = useState(null);
  const [loading, setLoading] = useState(!false)
  const [notFound, setNotfound] = useState(false)


  useEffect(() => {
    setLoading(true);
    if (paymentId) {
      getByLinkData(paymentId).then((res) => {

        if (res?.message === "payment already paid successfully") {
          toast.success("Payment already paid successfully");
          setLoading(false)
          setSuccessPage(true); // Show success page or update UI
          setAlreadyPaid(true)
          setCustomerData(res?.data?.customers[0]);
        }
        else if (res?.message === "payment link expired") {
          toast.success("Payment link expire");
          setLoading(false)
          setSuccessPage(true); // Show success page or update UI
          setExpirePage(true); // Show success page or update UI
          setCustomerData(res?.data?.customers[0]);

        }

        else if (res?.message === "get plan data" || "true") {
          setCustomerData(res?.data?.customers[0]);
          setPlan(res?.data?.plan);

          const endDate = parseInt(res?.data?.customers[0]?.durationInDays);
          console.log(endDate, "enddate", res.data.customers);

          const currentDate = new Date();
          const futureDate = new Date(
            currentDate.getTime() + endDate * 24 * 60 * 60 * 1000
          );

          const withTax = (parseInt(res?.data?.customers[0]?.numberOfUsers) * parseInt(res?.data?.customers[0]?.subscriptionPrice) * 18) / 100

          const finalValue = parseInt(res?.data?.customers[0]?.numberOfUsers) * parseInt(res?.data?.customers[0]?.subscriptionPrice) + withTax

          console.log(currentDate, futureDate, "6555555756");

          setTotalPrice(finalValue);
          // Set the dates in a format suitable for display or further use
          setStartDate(currentDate.toISOString()); // or use another format
          setEndDate(futureDate.toISOString()); // or use another format
        }
      }).catch((err) => {
        setNotfound(true)
      })
    }
    setLoading(false);

  }, []);



  const handlePayment = async () => {
    try {


      console.log(customerData, plan, "4564546546546");

      // Step 1: Create Order using the backend API
      const orderData = {
        amount: totalPrice, // Amount in paisa (INR 1000)
        currency: "INR",
        customerId: customerData?.id, // Customer ID from backend
        plan: customerData.subscriptionPlan,
        customerName: customerData?.firstName, // Customer name from backend
        customerEmail: customerData?.email,
        subDomainName: customerData.subDomain, // Customer email from backend
        durationInDays: customerData?.durationInDays,
        subscriptionPlan: customerData.subscriptionPlan,
        subscriptionStartDate: startDate,
        subscriptionEndDate: endDate,
        customerData: customerData,
        validity: customerData?.validity,
        planData: plan,
        paymentLinkId: paymentId
      };
      console.log(orderData, "orderData");

      const response = await createOrder(orderData); // Assuming createOrder sends a request to the backend to create an order



      const { id: order_id, currency, amount } = response.data;

      // Step 2: Razorpay options
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,  // Replace with your Razorpay Key ID
        amount: amount, // Amount in paisa
        currency: currency,
        name: "ApplogiQ",
        description: "Renewal Subscription Payment",
        order_id: order_id, // Razorpay order ID from backend
        handler: async function (response) {
          // Step 3: Handle successful payment here
          console.log(response, "response122");
          setLoading(true)
          try {
            await fetch(
              `${hostConfigure.API_URL}update_callback`,
              {
                method: "PUT",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(response),
              }
            )
              .then((response) => response.json())
              .then((res) => {
                if (res?.success) {
                  toast.success("Payment successful");
                  setLoading(false)
                  setSuccessPage(true); // Show success page or update UI
                } else {
                  toast.error("Oops your Payment is Failed!!!");
                  return;
                }
              });
          } catch (error) {
            console.log(error, "Fdghsgf");
          }


          const paymentData = {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
          };

          // You can send this paymentData to your backend for verification
          console.log("Payment Successful:", paymentData);
        },
        prefill: {
          name: customerData?.name || "Your Name",
          email: customerData?.email || "example@example.com",
          contact: customerData?.mobileNumber || "9999999999",
        },
        notes: {
          address: "Your address",
        },
        theme: {
          color: "#0F337C",
        },
      };

      // Step 4: Open Razorpay Checkout
      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } catch (error) {
      console.error("Error in payment process:", error);
    }
  };





  function formatDate(dateInput) {
    const date = new Date(dateInput);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat('en-GB', options).format(date);
  }


  return (
    <>
      {loading ? <Preloader /> : (
        <div className="w-[90%] mx-auto">
          {
            notFound ?
              (<div class="d-flex justify-content-center"> <img src={NotFound} width="500px" height="500px" />
              </div>) :

              (<>
                {!successPage ? (
                  <div className="sm:w-[90%]  m-auto pb-16">
                    <div>
                      <div className="text-center text-3xl xl:text-4xl text-[#0F337C] font-bold pt-16">
                        Renew Subscription
                      </div>
                      <p className="text-[#778399] sm:text-sm xl:text-base font-normal text-center mt-4">
                        Renew your subscription to keep enjoying all features and benefits
                        without interruption.
                      </p>
                    </div>

                    <div className="w-full sm:w-[95%]  xl:w-[85%] mx-auto pt-10">


                      <div className="lg:grid block lg:grid-cols-3  grid-cols-2 gap-7">

                        <div className="lg:col-span-2 col-span-1 bg-[#F6F9FF] rounded-xl md:rounded-3xl md:p-8 p-4 flex justify-center">

                          <div>
                            <table>
                              <tbody>
                                <tr>
                                  <td className="min-[320px]:text-[12px] sm:text-[16px] md:text-[18px]  text-[#000000] font-medium ">
                                    Company Name
                                  </td>
                                  <td className="text-[#0F337C] min-[320px]:text-[12px] sm:text-[16px] md:text-[18px]   text-capitalize font-semibold">
                                    <span className="md:pr-7 pr-2">:</span> {customerData?.companyName || "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="min-[320px]:text-[12px] sm:text-[16px] md:text-[18px]  text-[#000000] font-medium py-2">
                                    Time Period
                                  </td>
                                  <td className="text-[#0F337C] min-[320px]:text-[12px] sm:text-[16px] md:text-[18px]  font-semibold">
                                    <span className="md:pr-7 pr-2">:</span>{formatDate(startDate)} - {formatDate(endDate)}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="min-[320px]:text-[12px] sm:text-[16px] md:text-[18px] text-[#000000] font-medium">
                                    Company mail Id
                                  </td>
                                  <td className="text-[#0F337C] min-[320px]:text-[12px] sm:text-[16px] md:text-[18px]  font-semibold">
                                    <span className="md:pr-7 pr-2">:</span>{customerData?.email || "N/A"}
                                  </td>
                                </tr>





                                <tr>
                                  <td className="min-[320px]:text-[12px] sm:text-[16px] md:text-[18px]  text-[#000000] font-medium py-2">
                                    Mobile Number
                                  </td>
                                  <td className="text-[#0F337C] min-[320px]:text-[12px] sm:text-[16px] md:text-[18px]  font-semibold">
                                    <span className="md:pr-7 pr-2">:</span>   +{customerData?.mobileNumber || "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="min-[320px]:text-[12px] sm:text-[16px] md:text-[18px]  text-[#000000] font-medium ">
                                    Plan
                                  </td>
                                  <td className="text-[#0F337C] min-[320px]:text-[12px] sm:text-[16px] md:text-[18px]  font-semibold ">
                                    <span className="md:pr-7 pr-2">:</span>{customerData?.subscriptionPlan || "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="min-[320px]:text-[12px] sm:text-[16px] md:text-[18px]  text-[#000000] font-medium py-2">
                                    Number of Users
                                  </td>
                                  <td className="text-[#0F337C] min-[320px]:text-[12px] sm:text-[16] md:text-[18px]  font-semibold">
                                    <span className="md:pr-7 pr-2">:</span>{customerData?.numberOfUsers || "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="min-[320px]:text-[12px] sm:text-[16px] md:text-[18px]  text-[#000000] font-medium py-1">
                                    Price per Users
                                  </td>
                                  <td className="text-[#0F337C] min-[320px]:text-[12px] sm:text-[16px] md:text-[18px]  font-semibold">
                                    <span className="md:pr-7 pr-2">:</span>{customerData?.subscriptionPrice.toLocaleString('en-US') || "N/A"} <span className="text-[12px]">+18% GST </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <hr className="border-[#99BBFF]   border-[2px] lg:hidden my-4 " />

                        <div className="col-span-1  flex justify-center items-center" >

                          <div className=" bg-[#F6F9FF]  p-4 rounded-xl md:rounded-3xl">

                            <div>
                              <div className="flex justify-between">
                                <div className="sm:text-[22px] xl:text-[30px] font-medium text-[##354667]">
                                  Total
                                </div>
                                <div className="sm:text-[30px] xl:text-[38px] text-[#0F337C] font-semibold">
                                  ₹{totalPrice.toLocaleString('en-US')}
                                  <span className="text-[#778399] text-base font-medium">
                                    /{customerData?.durationInDays === 365 || "365" ? "yearly" : "monthly "}{" "}
                                  </span>
                                </div>
                              </div>
                            </div>



                            <div className="w-100 my-4">
                              <button onClick={handlePayment} className="text-center bg-[#051F54] text-white w-full rounded-lg h-[48px]">
                                Pay now
                              </button>
                            </div>

                            <div className="flex justify-between">
                              <div className="align-middle relative">
                                <img src={InfoIcon} alt="info" className="w-14 xl:w-10 h-14 xl:h-10" />
                              </div>
                              <div className="text-[#778399] text-[14px] leading-loose font-medium text-left pl-3">
                                If you have any questions or need assistance with your renewal,
                                our support team is here to help. Contact us at{" "}
                                <span className="text-[#0F337C] font-semibold cursor-pointer">
                                  support@leadsynq.com.
                                </span>{" "}
                                {/* or call{" "} */}
                                {/* <span className="text-[#0F337C] cursor-pointer font-semibold">
                                  +1 (555) 123-4567.
                                </span> */}
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>

                    </div>

                  </div>


                ) : (
                  <SuccessPage subDomain={customerData?.subDomain} isPaymentExpired={expirePage} isAlreadyPaid={alreadyPaid} />
                )}</>)

          }

        </div >


      )
      }
    </>
  );
}

export default RenewSubscription;
