import React from "react";
import NotFound from '../../assets/images/leadq-404.png'

const Index = () => {
    return (
        <div class="d-flex justify-content-center"> <img src={NotFound} width="500px" height="500px" />
        </div>
    );
};

export default Index;