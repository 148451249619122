import React from 'react'
import leadSynQLogo from "../../assets/images/logo.png"

function Header() {
  return (
    <div className='border-b border-[#9DA3B2]'>
      <div className=' container m-auto h-[76px] flex items-center' >
        <div className='w-[90%] m-auto py-2 '>
          <div><img src={leadSynQLogo} alt='leadsynq-logo' width={155} height={40} /></div>
        </div>
      </div>
    </div>
  )
}

export default Header