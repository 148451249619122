import React from 'react'
import logo from '../../assets/images/logo.png'
import addressIcon from '../../assets/icons/addressIcon.png'
import phoneIcon from '../../assets/icons/phoneIcon.png'
import smsIcon from '../../assets/icons/smsIcon.png'
import Instagram from '../../assets/icons/Instagram.png'
import Twitter from '../../assets/icons/twitter.png'
import Reddit from '../../assets/icons/Reddit.png'
import LinkedIn from '../../assets/icons/LinkedIn.png'
import Discord from '../../assets/icons/Discord.png'


const Footer = () => {
    const handleMailTo = (email) => {
        window.location.href = `mailto:${email}`;
    };

    // const getCurrentYear = new Date().getFullYear();

    return (

        // grid md:grid-cols-3 grid-cols-1 items-start md:gap-[100px] gap-[20px] mt-[80px]

        <div className=''>


            <div className='container w-[90%] m-auto flex items-start md:flex-row  flex-col mt-[20px] justify-between'>

                <div className='md:w-[25%] w-[100%]'>
                    <img src={logo} className='mb-[10px]' width={169} height={44} alt="logo" />
                    <p className='m-0 text-[#15233D] text-sm md:text-base'>Take the guesswork out of lead management and drive your sales forward with our intuitive
                        leads management.</p>
                </div>

                <div className='md:w-[25%] w-[100%] md:mt-0 mt-5  block md:flex justify-center'>
                    <div>
                        <p className='text-[#050E21] text-[17px] font-semibold' >Visit Us</p>
                        <div className='flex items-start gap-2 pt-4'>
                            <img width={16} height={16} src={addressIcon} alt="" />
                            <p className='m-0 text-[#15233D] font-medium text-sm md:text-base ' >{"Floor-3, Sathyam Towers,"} <br />{"6D Bharathi Street,Valipalayam,"}
                                <br />{"Tiruppur - 641 601"}</p>
                        </div>
                    </div>
                </div>

                <div className='md:w-[25%] w-[100%] md:mt-0 mt-5 block md:flex justify-end '>
                    <div>
                        <p className='md:mb-[15px] text-[#050E21] text-[17px] font-semibold' >For Enquiry</p>
                        <div className='flex items-center gap-2 mt-3'>
                            <img width={16} height={16} src={phoneIcon} alt="" />
                            <p className='m-0 text-[#15233D] text-sm md:text-base font-medium'>+91 96299 38033</p>
                        </div>
                        <div className='flex items-center gap-2 mt-1'>
                            <img width={16} height={16} src={smsIcon} alt="" />
                            <p onClick={() => handleMailTo('partnerships@applogiq.org')} className='m-0 cursor-pointer text-[#15233D] text-sm md:text-base font-medium'>partnerships@applogiq.org</p>
                        </div>
                    </div>
                </div>


            </div>
            <div className='bg-[#F7F8FA] mt-[20px]'>
                <div className='  w-[90%] m-auto'>
                    <div className='container py-[12px] flex md:flex-row flex-col gap-[20px] md:gap-0 justify-between items-center'>
                        <p className='m-0 text-[#888888] text-[14px]'>{"© Copyright 2024, All Rights Reserved by"} <span className='text-[#0F337C]'>ApplogiQ</span></p>
                        <div className='flex items-center gap-[15px]'>
                            <img className='cursor-pointer' width={28} src={Instagram} alt="icon" />
                            <img className='cursor-pointer' width={28} src={Twitter} alt="icon" />
                            <img className='cursor-pointer' width={28} src={Reddit} alt="icon" />
                            <img className='cursor-pointer' width={28} src={LinkedIn} alt="icon" />
                            <img className='cursor-pointer' width={28} src={Discord} alt="icon" />
                        </div>
                    </div>
                </div>
            </div>


        </div>

    )
}

export default Footer
