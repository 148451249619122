import { hostConfigure } from "../config/index";
// import cookie from "react-cookies";

const responseHandler = (response) => {
  if (response.status === 401) {
    // cookie.remove("token");
  } else if (response.status === 200 || response.status === 201) {
    return response;
  } else {
    return false;
  }
};

const errorHandler = (error) => {
  return false;
};

const postDataApi = (requestUrl, params) => {
  // const token = cookie.load("token");
  return fetch(`${hostConfigure.API_URL}${requestUrl}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  })
    .then((response) => {
      return responseHandler(response);
    })
    .then((result) => {
      // return result;
      return result.status === 200 ||
        result.status === 201 ||
        result.status === 400 ||
        result.status === 422 ||
        result.status === 502
        ? result.json()
        : result;
    })
    .catch((error) => {
      errorHandler(error);
    });
};

const getListByApi = (requestUrl, params) => {

  let getParams = "?";

  if (params && params.subDomain && params.subDomain !== undefined) {
    getParams += `subDomain=${params.subDomain}`;
  }

  if (params && params.subdomain && params.subdomain !== undefined) {
    getParams += `subdomain=${params.subdomain}`;
  }

  return fetch(`${hostConfigure.API_URL}${requestUrl}${getParams}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return responseHandler(response);
    })
    .then((result) => {
      return result.json();
    })
    .catch((error) => {
      errorHandler(error);
    });
};

const viewDataByApi = async (requestUrl, dataId) => {
  const token = await localStorage.getItem("token");
  return fetch(`${hostConfigure?.API_URL}${requestUrl}/${dataId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      // Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      return responseHandler(response);
    })
    .then((result) => {
      return result.json();
    })
    .catch((error) => {
      errorHandler(error);
    });
};

export { postDataApi, getListByApi, viewDataByApi };
